import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';

function ClientForm({ values, setPage, getData, approved }) {
  if (values?.time){
    values.time = values.time.toString()
  }
  const [valuesForm, setValuesForm] = useState(values)
  const [agencies, setAgencies] = useState([])
  const { width, height, ref } = useResizeDetector();
  const [groups, setGroups] = useState([])

  useEffect(() => {
    axios
      .get(
        Constants.APIEndpoints.AGENCY + "/getAllAgencies")
      .then((res) => {
        setAgencies(res.data)
      })

    axios
      .get(
        Constants.APIEndpoints.STANDARD + "/group")
      .then((res) => {
        setGroups(res.data)
      })

  }, [])

  const fields = [
    {
      col: 10,
      type: "text",
      name: "fancy_name",
      label: "Nome Fantasia",
      required: true

    },
    {
      col: 2,
      type: 'number',
      name: 'time',
      label: 'Prazo'
    },
    {
      col: 6,
      type: "text",
      name: "company_name",
      label: "Razão Social",
      required: true
    },
    {
      col: 6,
      type: "text",
      name: "cnpj",
      label: "CNPJ",
      format: '##.###.###/#####-##',

    },

    {
      col: 4,
      type: "text",
      name: "contat",
      label: "Contato",
    },
    {
      col: 4,
      type: "text",
      name: "phone",
      label: "Telefone",
    },

    {
      col: 4,
      type: "text",
      name: "email",
      label: "Email",
    },
    {
      col: 10,
      type: "text",
      name: "address",
      label: "Endereço",
    },
    {
      col: 2,
      type: "text",
      name: "label",
      label: "Label",
    },
    {
      col: 4,
      type: "select",
      name: "fk_id_agency",
      label: "Agência",
      options: agencies.map(v => {
        return {
          value: v.id_agency,
          label: v.fancy_name
        }
      }),

    },
    {
      col: 4,
      type: "text",
      name: "fk_id_group",
      label: "Grupo",
    },
    {
      col: 4,
      type: "text",
      name: "market_segment",
      label: "Segmento de Mercado",
    },

  ];


  const onSubmit = () => {
    axios.post(Constants.APIEndpoints.CLIENT + (values.id_client ? '/updateClient' : '/createClient'), valuesForm).then(res => {
      setPage('list')
      getData(approved);
    }).catch(error => {
      console.log(error)
    })


  }

  return (
    <div ref={ref}>
      <CommonHeader title="Criar Cliente" onBack={() => setPage('list')} width={width}
      />
      <CommonForm
        values={valuesForm}
        hasHeader={true}
        fields={fields}
        onChangeField={(f, v) => {
          values[f.name] = v;
          setValuesForm(values)
        }}
        onSubmit={onSubmit}
      />

    </div>
  );
}

export default ClientForm;
