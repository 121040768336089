import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import { useResizeDetector } from "react-resize-detector";
import moment from "moment-timezone";
export default function ProposalProductForm({ params, tags, id_proposals, vehicle, products, values, setPage, getData, access }) {
  const { width, height, ref } = useResizeDetector();
  if (values?.tags) {
    values.tags_ = values.tags.map((t) => t.id_tags);
  }
  const [valuesProduct, setValuesProduct] = useState(values);
  const [status, setStatus] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
 
    axios.get(Constants.APIEndpoints.STATUS + "/getAllStatus").then((res) => {
      setStatus(res.data[0]);
    });

    axios.get(Constants.APIEndpoints.USER + "/getAllUsersSimple").then((res) => {
      setUsers(res.data);
    });

    if (values.dt_start) {
      let values_ = valuesProduct;
      values_.dt_start = moment(values.dt_start).format("YYYY-MM-DD");
      setValuesProduct({ ...values_ });
    }
    if (values.dt_end) {
      let values_ = valuesProduct;
      values_.dt_end = moment(values.dt_end).format("YYYY-MM-DD");
      setValuesProduct({ ...values_ });
    }
  }, []);

  const addProduct = (v) => {
    let values = valuesProduct;
    values.fk_id_proposal = id_proposals;
    values.delivered_status = values.quantity_delivered > values.quantity_hired ? 2 : 1
    const url = values.id_rel_proposal_product ? "/updateproposalproduct" : "/saveproposalproduct";
    axios.post(Constants.APIEndpoints.PROPOSAL + url, values).then((res) => {
      getData();
      setPage("list");
    });
  };
 
  let fieldsProduct = [
    {
      col: 3,
      type: "select",
      name: "fk_id_product",
      label: "Produto",
      options: (products.filter(p => p.fk_id_vehicle == vehicle) || []).map(p => ({ value : p.id_product, label: p.name}))
    },
    {
      col: 9,
      type: "text",
      name: "objective",
      label: "Objetivo",
    },
    {
      col: 4,
      type: "select",
      name: "fk_id_executive",
      label: "Executivo",
      options: users.map((user) => ({ value: user.id_user, label: user.name })),
      visible: false,
    },
    // {
    //     col: 2,
    //     type: "money",
    //     name: "price",
    //     label: "Preço",
    // },
    {
      col: 5,
      type: "number",
      name: "quantity_hired",
      label: "Quantidade contratada",
    },
    {
      col: 5,
      type: "number",
      name: "quantity_delivered",
      label: "Quantidade Entregue",
    },
    {
      col: 2,
      type: "text",
      name: "percent_delivered",
      label: "Porcentagem entregue",
      disabled: true,
    },
    // {
    //     col: 4,
    //     type: "number",
    //     name: "negociation",
    //     label: "Desconto",
    // },
    {
      col: 3,
      type: "date",
      name: "dt_start",
      label: "Data Inicial",
    },
    {
      col: 3,
      type: "date",
      name: "dt_end",
      label: "Data Final",
    },
    {
      col: 3,
      type: "number",
      name: "days",
      label: "Dias",
    },
    {
      col: 3,
      label: 'Etiquetas',
      name: "tags_",
      type: "multiselect",
      options: tags.map((k) => ({
        value: k.id_tags,
        label: k.name,
        color: k.color,
      })),
    },

    {
      col: 3,
      type: "money",
      name: "platform_costs",
      label: "Custos da Plataforma",
      visible: access.visualize_platform_costs
    },

    {
      col: 3,
      type: "money",
      name: "extras_costs",
      label: "Custos Extras",
    },
    {
      col: 3,
      type: "money",
      name: "investment",
      label: "Investimento",
    },
    {
      col: 3,
      type: "text",
      name: "percent_costs",
      label: "Porcentagem de custos",
      disabled: true,
    },

    {
      col: 3,
      type: "money",
      name: "lost",
      label: "Perdas",
    },
    {
      col: 3,
      type: "money",
      name: "gross_billed",
      label: "Valor Bruto",
    },
    {
      col: 3,
      type: "money",
      name: "net_billed",
      label: "Valor Líquido",
    },
    {
      col: 3,
      type: "text",
      name: "nf",
      label: "Nota Fiscal",
      visible: false,
    },
    {
      col: 12,
      type: "textarea",
      name: "observation",
      label: "Observações",
    },
  ];

  return (
    <div ref={ref}>
      <CommonHeader title={"Produtos"} onBack={() => setPage("list")} width={width} />
      <div
        style={{
          marginTop: 120,
        }}
      >
        <CommonForm
          values={{...valuesProduct,
            quantity_delivered : valuesProduct.quantity_delivered.toString(),
            quantity_hired : valuesProduct.quantity_hired.toString(),
            days : valuesProduct.days.toString()
          }}
          fields={fieldsProduct}
          onChangeField={(f, v) => {
            let newProduct = valuesProduct;
           
            if (f.name == "dt_end" && valuesProduct.dt_start) {
              newProduct.days = moment(v).diff(moment(valuesProduct.dt_start), "days") + 1;
            }
            if (f.name == "dt_start" && valuesProduct.dt_end) {
              newProduct.days = moment(v).diff(moment(valuesProduct.dt_start), "days") + 1;
            }
            if (f.name == "days" && valuesProduct.dt_end) {
              newProduct.dt_end = moment(valuesProduct.dt_start)
                .add(v - 1, "day")
                .format("YYYY-MM-DD");
            }
            if (f.name == "quantity_hired") {
              newProduct.percent_delivered = `${
                newProduct.quantity_delivered ? (((newProduct.quantity_delivered || 0) / v) * 100).toFixed(2) : 0
              }%`;
            }

            if (f.name == "quantity_delivered") {
              newProduct.percent_delivered = `${((v / (newProduct.quantity_hired || 0)) * 100).toFixed(2)}%`;
            }
            if (f.name == "platform_costs") {
              const extras_costs = newProduct.extras_costs || 0;
              const investment = newProduct.investment || 0;
              newProduct.percent_costs =
                investment == 0 ? "0%" : `${(((parseFloat(v) + parseFloat(extras_costs)) / investment) * 100).toFixed(2)}%`;
            }
            if (f.name == "extras_costs") {
              const platform_costs = newProduct.platform_costs || 0;
              const investment = newProduct.investment || 0;
              newProduct.percent_costs =
                investment == 0 ? "0%" : `${(((parseFloat(platform_costs) + parseFloat(v)) / investment) * 100).toFixed(2)}%`;
            }

            if (f.name == "investment") {
              const platform_costs = newProduct.platform_costs || 0;
              const extras_costs = newProduct.extras_costs || 0;
              newProduct.percent_costs =
                v == 0 ? "0%" : `${(((parseFloat(platform_costs) + parseFloat(extras_costs)) / v) * 100).toFixed(2)}%`;

              const lost = newProduct.lost || 0;
              newProduct.gross_billed = parseFloat(v) + parseFloat(lost);
              newProduct.net_billed = parseFloat(newProduct.gross_billed * 0.8).toFixed(2);
            }
            if (f.name == "lost") {
              const investment = newProduct.investment || 0;
              newProduct.gross_billed = parseFloat(v) + parseFloat(investment);
              newProduct.net_billed = parseFloat(newProduct.gross_billed * 0.8).toFixed(2);
            }
            newProduct[f.name] = v;
            setValuesProduct({ ...newProduct });
          }}
          onSubmit={addProduct}
        />
      </div>
    </div>
  );
}
